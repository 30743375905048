import React, { useEffect, useState, useContext } from 'react';
import { toast } from 'react-toastify';
import Select from '@atlaskit/select';
import api from '~/services/api';
import { MacroContext } from '~/context/macros';

interface IMacro {
  cod_item: number;
  valor: any;
  des_item: string;
}

interface MacroProps {
  tipoInfo: number;
  onChange(e: any): void;
  defaultValue: any;
}
const Macro: React.FC<MacroProps> = ({ tipoInfo, onChange, defaultValue }) => {
  const [macros, setMacros] = useState<IMacro[]>([]);
  const [value, setValue] = useState<{ label: string; value: string }>();
  const [options, setOptions] = useState<
    Array<{ value: string; label: string }>
  >([]);

  const { getMacro, addMacro } = useContext(MacroContext);

  useEffect(() => {
    (async () => {
      try {
        const findMacro = getMacro(tipoInfo);

        if (findMacro) {
          setMacros(findMacro.items);
        } else {
          const { data } = await api.get(`/macros/${tipoInfo}`);
          if (data.data) {
            setMacros(data.data);

            addMacro({
              cod_tipo_info: tipoInfo,
              items: data.data,
            });
          }
        }
      } catch (e: any) {
        toast.error(e.data.message);
      }
    })();
  }, [tipoInfo]);

  useEffect(() => {
    const newOptions = macros.map(({ des_item, cod_item }) => ({
      label: des_item,
      value: String(cod_item),
    }));

    setOptions(newOptions);
  }, [macros]);

  useEffect(() => {
    if (defaultValue !== '') {
      const findDefault = macros.find(
        (m) => m.cod_item === parseInt(defaultValue, 10),
      );

      if (findDefault) {
        setValue({
          label: findDefault.des_item,
          value: String(findDefault.cod_item),
        });
      }
    }
  }, [defaultValue, macros]);

  const handleChange = (e: any) => {
    if (e) {
      setValue(e);
      onChange(e);
    }
  };
  return (
    <Select
      placeholder="Selecione"
      options={options}
      className="single-select"
      classNamePrefix="react-select"
      onChange={handleChange}
      value={value}
    />
  );
};

export default Macro;
