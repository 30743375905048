import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  margin: auto;
`;

export const ContainerItem = styled.div`
  width: 100%;
  display: grid;
  margin: auto;
  gap: 5px;
  height: 37.5rem;
  grid-template-columns: 1fr 60px;
  grid-template-rows: 30px 300px;
  .title {
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 1;
  }
`;

export const List = styled.div`
  border: 1px solid #dfe1e6;
  border-radius: 3px;
  color: #fff;
  overflow-y: auto;
  height: 34.375rem;

  p {
    color: #fff;
    text-align: center;
    margin-top: 20px;
  }
  .selected {
    background: #22115a;
    color: #fff;
  }
`;

export const ListaItem = styled.div`
  display: flex;
  width: 100%;
  color: var(--ds-text-lowEmphasis, var(--ds-text-lowEmphasis, #6b778c));
  align-items: center;
  padding: 5px;
  height: 40px;
  gap: 5px;
`;

export const Controller = styled.div`
  display: grid;
  height: 200px;
  align-items: center;
  justify-items: center;
  margin: auto;
  grid-template-columns: 60px;
  grid-template-rows: repeat(4, 1fr);
`;

export const Button = styled.button`
  width: 100%;
  height: 40px;
  background: #22115a;
  color: #fff;
  border: none;
  border-radius: 5px;
`;
