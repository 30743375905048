import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  .isDisabled {
    cursor: no-drop;
  }
`;
interface inValid {
  IsInvalid: boolean;
}

export const InputLojas = styled.div<inValid>`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  gap: 10px;
  padding: 5px;
  border: 2px solid #dfe1e6;
  border-radius: 3px;
  .ButtonLoja {
    min-width: 70px;
    background-color: #8850bf;
    color: #fff;
    border-radius: 5px;
    padding: 4px 25px 5px 30px;
    cursor: pointer;
    transition: 0.3s;
  }
  .form-check-input:focus {
    border-color: transparent !important;
    box-shadow: transparent !important;
  }
  .form-check-input:checked {
    background-color: #8850bf; //57069e
    border-color: transparent !important;
    transition: 0.3s;
  }
  ${(props) => props.IsInvalid && 'border-bottom: 2px solid #cf1919'}
`;
