import React from 'react';

import { Container, Content } from './styles';
import Navigation from '~/components/Navigation';
import Navbar from '../Navbar';

interface TypeTeste {
  url?: string;
}

const Layout: React.FC<TypeTeste> = ({ url }) => {
  return (
    <Container>
      <Navbar />
      {/* <Sidebar /> */}
      <Content>
        <Navigation url={url} />
      </Content>
    </Container>
  );
};

export default Layout;
