import React, { useState, useEffect, useCallback } from 'react';
import { Form } from 'react-bootstrap';
import Tooltip from '@atlaskit/tooltip';
import Select from '@atlaskit/select';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Container, InputLojas } from './styles';
import api from '~/services/api';
import useAuth from '~/hooks/useAuth';
import { toast } from 'react-toastify';

interface ILoja {
  cod_loja: number;
  des_loja: string;
  des_cidade: string;
  flg_padrao: boolean;
  flg_checked: boolean;
  des_uf: string;
  num_cnpj: string;
  tipo_regime: number;
  flg_inativa: boolean;
}
interface ISelectLoja {
  value: number;
  label: string;
  uf?: string;
  selected?: boolean;
}
interface ResponseLoja {
  data: ILoja[];
  success: boolean;
}
interface LojaProps {
  disabled?: boolean;
  selectedLoja?: number | Array<number>;
  resetLojas?: boolean;
  onChange(value: number | Array<number>): void;
  onChangedObject?(value: any): void;
  isMulti?: boolean;
  isDisabled?: boolean;
  IsInvalid?: boolean;
}
/**
 *
 * @param labelText Texto que aparecerá em frente a linha de separação
 * @returns Componente para separar áreas do formulário como dados pessoais, contato, endereço, etc.
 */
const Loja: React.FC<LojaProps> = ({
  selectedLoja,
  disabled,
  resetLojas = false,
  onChange,
  isMulti = false,
  IsInvalid = false,
  isDisabled = false,
  onChangedObject,
}) => {
  const [loading, setLoading] = useState(true);
  const [all, setAll] = useState(false);
  const [lojas, setLojas] = useState<ILoja[]>([]);
  const [optionsSelect, setOptionsSelect] = useState<ISelectLoja[]>([]);
  const [optionsSelectActive, setOptionsSelectActive] = useState<ISelectLoja[]>(
    [],
  );
  const [value, setValue] = useState<ISelectLoja | null>(null);
  const { user } = useAuth();

  useEffect(() => {
    if (resetLojas) {
      if (lojas.length === 1) {
        return handleSetAllLojas();
      }
      const arrayLojas = lojas;

      lojas.map((loja, index) => {
        if (loja.flg_padrao === true) {
          arrayLojas[index].flg_checked = true;
          return;
        }

        arrayLojas[index].flg_checked = false;
        return arrayLojas;
      });

      setAll(false);
      setLojas(arrayLojas);
      const newValue = [];
      newValue.push(user?.loja);

      const arrayFiltrado: number[] = newValue.filter(
        (n: number | undefined): n is number => typeof n === 'number',
      );
      onChange(arrayFiltrado);
    }
  }, [resetLojas]);

  const handleSetAllLojas = () => {
    if (!all) {
      setAll(true);
      const newLojas = lojas.map((l: ILoja) => ({
        ...l,
        flg_checked: true,
      }));
      setLojas(newLojas);
      const newValue = newLojas.map((l: ILoja) => l.cod_loja);
      onChange(newValue);
    } else {
      setAll(false);
      const newLojas = lojas.map((l: ILoja) => ({
        ...l,
        flg_checked: l.cod_loja === Number(user?.loja),
      }));
      setLojas(newLojas);
      onChange(isMulti ? [newLojas[0].cod_loja] : []);
    }
  };
  const onChangeValue = (id: number) => {
    const newLojas = lojas.map((l: ILoja) => {
      if (l.cod_loja === id) {
        return {
          ...l,
          flg_checked: !l.flg_checked,
        };
      }
      return l;
    });
    const existeLojaSelecionada = newLojas.filter(
      (loja) => loja.flg_checked === true,
    );

    if (existeLojaSelecionada.length > 0) {
      const isAnyLojaSelected = newLojas.find(
        (bool) => bool.flg_checked === true,
      );
      if (!isAnyLojaSelected) {
        setAll(false);
      }
      const isAnyLojaUnSelected = newLojas.find(
        (bool) => bool.flg_checked === false,
      );
      if (!isAnyLojaUnSelected) {
        setAll(true);
      } else {
        setAll(!true);
      }
      setLojas(newLojas);
      const filteredLojas = newLojas.filter((l: ILoja) => l.flg_checked);
      const newValue = filteredLojas.map((l: ILoja) => l.cod_loja);

      // Filtrar as lojas com base nos códigos fornecidos
      const lojasFiltradas = lojas.filter((loja: any) =>
        newValue.includes(loja.cod_loja),
      );
      if (onChangedObject) onChangedObject(lojasFiltradas);
      onChange(newValue);
    } else {
      toast.warning('Ao menos uma loja deve estar selecionada');
    }
  };
  const onChangeValueSimple = (opt: ISelectLoja | null) => {
    if (opt) {
      setValue(opt);
      onChange(opt.value);
      if (onChangedObject) onChangedObject(opt);
    }
  };
  const getLojas = useCallback(async () => {
    setLoading(true);
    try {
      if (user && user.id) {
        const { data } = await api.get<ResponseLoja>(`/lojas/usuario`);

        if (
          Array.isArray(selectedLoja) &&
          selectedLoja.length === data.data.length
        ) {
          setAll(true);
        }

        if (data.success) {
          if (isMulti) {
            const newLojas = (() => {
              // Verifica se selectedLoja corresponde a algum cod_loja das lojas
              const isSelectedLojaPresent = data.data.some((l) =>
                Array.isArray(selectedLoja)
                  ? selectedLoja.includes(l.cod_loja)
                  : selectedLoja === l.cod_loja,
              );

              return data.data
                .filter((l: ILoja) => l.flg_inativa !== true)
                .map((l: ILoja) => {
                  let isSelected = false;

                  if (isSelectedLojaPresent) {
                    // Se selectedLoja corresponder a algum cod_loja, marca apenas essas lojas
                    isSelected = Array.isArray(selectedLoja)
                      ? selectedLoja.includes(l.cod_loja)
                      : selectedLoja === l.cod_loja;
                  } else {
                    // Se nenhum cod_loja corresponder a selectedLoja, marca a loja padrão
                    isSelected = l.flg_padrao;
                  }

                  return {
                    ...l,
                    flg_checked: isSelected,
                  };
                });
            })();

            setLojas(newLojas);
            const filteredLojas = newLojas.filter((l: ILoja) => l.flg_checked);
            const newValue = filteredLojas.map((l: ILoja) => l.cod_loja);
            onChange(newValue);
          } else {
            const createOption = (loja: ILoja, selected: boolean) => {
              return {
                label:
                  loja.cod_loja < 10
                    ? `0${loja.cod_loja} - ${loja.des_loja} (${loja.des_cidade})`
                    : `${loja.cod_loja} - ${loja.des_loja} (${loja.des_cidade})`,
                value: loja.cod_loja,
                selected,
                des_loja: loja.des_loja,
                uf: loja.des_uf,
                cod_loja: loja.cod_loja,
                num_cnpj: loja.num_cnpj,
                tipo_regime: loja.tipo_regime,
              };
            };

            const options = [] as ISelectLoja[];
            const optAtivo = [] as ISelectLoja[];

            data.data.forEach((loja: ILoja) => {
              const selected = loja.cod_loja === selectedLoja;

              if (loja.flg_inativa !== true) {
                optAtivo.push(createOption(loja, selected));
              }
              options.push(createOption(loja, selected));

              if (selected) {
                setValue({ value: loja.cod_loja, label: loja.des_loja });
              }
            });

            setOptionsSelect(options);
            setOptionsSelectActive(optAtivo);

            const opt = options.find((o) => o.selected === true);
            if (opt) onChange(opt.value);
          }
        }
      }
    } finally {
      setLoading(false);
    }
  }, [isMulti, user]);
  useEffect(() => {
    getLojas();
  }, [getLojas]);
  useEffect(() => {
    const loja = optionsSelect.find((lj) => lj.value === selectedLoja);
    if (loja) {
      setValue(loja);
    } else {
      setValue(null);
    }
  }, [optionsSelect, selectedLoja]);
  useEffect(() => {
    if (lojas.length === 1) {
      setAll(true);
    }
  }, [lojas]);

  return (
    <Container>
      <div className="row">
        {/* Lista de Lojas */}
        <div className="col-12">
          <label
            htmlFor="input-lojas"
            className="sc-iqAclL lflqij css-1bwberi-Label"
          >
            {isMulti ? 'Lojas' : 'Loja'}
          </label>
          {isMulti && (
            <InputLojas id="input-lojas" IsInvalid={IsInvalid}>
              {loading && (
                <CircularProgress
                  size={15}
                  style={{ color: '#8850bf', margin: '10px 0px 0px 10px' }}
                />
              )}
              {!loading && (
                <Tooltip position="top" content="TODAS AS LOJAS">
                  <div
                    onClick={() => {
                      if (!disabled) handleSetAllLojas();
                    }}
                  >
                    <Form.Check
                      className="ButtonLoja"
                      type="checkbox"
                      label="Todas"
                      disabled={disabled}
                      checked={all}
                      onChange={() => null}
                      style={{
                        backgroundColor: all ? '#57069e' : '#8850bf',
                        cursor: disabled ? 'not-allowed' : 'pointer',
                      }}
                    />
                  </div>
                </Tooltip>
              )}
              {lojas &&
                !loading &&
                lojas.map((loja: any, index: any) => {
                  return (
                    <Tooltip
                      position="top"
                      content={`LOJA ${loja.cod_loja} - ${loja.des_loja}`}
                      key={loja.cod_loja}
                    >
                      <div
                        onClick={() => {
                          if (!disabled) onChangeValue(loja.cod_loja);
                        }}
                      >
                        <Form.Check
                          className="ButtonLoja"
                          type="checkbox"
                          disabled={disabled}
                          label={`${loja.cod_loja}`}
                          checked={loja.flg_checked}
                          onChange={() => null}
                          style={{
                            backgroundColor: loja.flg_checked
                              ? '#57069e'
                              : '#8850bf',
                            cursor: disabled ? 'not-allowed' : 'pointer',
                          }}
                        />
                      </div>
                    </Tooltip>
                  );
                })}
            </InputLojas>
          )}
          {!isMulti && (
            <div
              style={{ borderBottom: IsInvalid ? '2px solid #cf1919' : 'none' }}
            >
              <Select
                className={isDisabled === true ? 'isDisabled' : ''}
                options={optionsSelectActive}
                inputId="loja"
                value={value || null}
                placeholder="Selecione a Loja"
                noOptionsMessage={() => 'Nenhum registro encontrado'}
                onChange={onChangeValueSimple}
                isDisabled={isDisabled}
              />
            </div>
          )}
        </div>
      </div>
    </Container>
  );
};
export default Loja;
