/* eslint-disable prettier/prettier */
/* eslint-disable no-shadow */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import {
  MdKeyboardArrowUp,
  MdKeyboardArrowDown,
  MdArrowUpward,
  MdArrowDownward,
} from 'react-icons/md';
import {
  Container,
  ContainerItem,
  Controller,
  List,
  Button,
  ListaItem,
} from './styles';

interface OptionsProps {
  order: number;
  cod_campo: number;
  des_campo: string;
}

interface DualListBoxProps {
  optionSelected: OptionsProps[]
  setOptionSelected: (item: OptionsProps[]) => void;
}
interface SeparateOptionsProps {
  allOptions: OptionsProps[];
  cod_campo: number | undefined;
}

interface SortOptionsProps {
  allOptions: OptionsProps[];
  setOptions: (item: OptionsProps[]) => void;
}
interface UniqueOptionsProps {
  allOptions: OptionsProps[];
}

export const ListBox: React.FC<DualListBoxProps> = ({
  optionSelected,
  setOptionSelected,
}) => {
  const [selected, setSeleted] = useState<number>();

  useEffect(() => {
    document.getElementById(`${selected}`)?.focus();
  }, [optionSelected]);

  function handleCampo(cod_campo: number): void {
    setSeleted(cod_campo);
  }

  function moveItemSelectedToMaxUpOrMaxDown(action: string) {
    const { option, options } = separateOptions({
      allOptions: optionSelected,
      cod_campo: selected,
    });
    if (option) {
      if (action === 'up') {
        options.unshift(option);
      } else {
        options.splice(options.length, 0, option);
      }
      sortOptions({
        allOptions: options,
        setOptions: setOptionSelected,
      });
    }
  }

  function moveItemSelectedToUpOrDown(action: string) {
    const { option, options } = separateOptions({
      allOptions: optionSelected,
      cod_campo: selected,
    });
    if (option) {
      if (action === 'up') {
        const order = option.order - 2;
        options.splice(order < 0 ? 0 : order, 0, option);
      } else {
        options.splice(option.order, 0, option);
      }
      sortOptions({
        allOptions: options,
        setOptions: setOptionSelected,
      });
    }
  }

  function separateOptions({ allOptions, cod_campo }: SeparateOptionsProps) {
    const options = allOptions.filter((item) => item.cod_campo !== cod_campo);
    const option = allOptions.find((item) => item.cod_campo === cod_campo);

    return {
      options,
      option,
    };
  }
  function sortOptions({ allOptions, setOptions }: SortOptionsProps) {
    const dataOrdenado = allOptions.map((item, index) => ({
      order: index + 1,
      cod_campo: item.cod_campo,
      des_campo: item.des_campo,
    }));
    const dataUnique = uniqueOptions({ allOptions: dataOrdenado });
    setOptions(dataUnique);
  }
  function uniqueOptions({ allOptions }: UniqueOptionsProps) {
    const dataUnique = [];
    const map = new Map();
    // eslint-disable-next-line no-restricted-syntax
    for (const option of allOptions) {
      if(!map.has(option.cod_campo)) {
        map.set(option.cod_campo, true);    // set any value to Map
        dataUnique.push({
          order: option.order,
          cod_campo: option.cod_campo,
          des_campo: option.des_campo,
        });
      }
    }
    return dataUnique;
  }

  function handleOnDragEnd(result: any): void {
    const allArray = optionSelected.filter(item => item.cod_campo !== selected);
    const destino = result.destination.index;
    const findOne = optionSelected.find(item => item.cod_campo === selected);
    if (findOne) {
        allArray.splice(destino, 0, findOne);
    }
    const dataOrdenado = allArray.map((item, index) => ({
        order: index + 1,
        cod_campo: item.cod_campo,
        des_campo: item.des_campo,
    }));
    setOptionSelected(dataOrdenado);
}

  return (
    <Container>
      <DragDropContext onDragEnd={handleOnDragEnd}>
        <ContainerItem>
          <span className="title">Módulos Ativos</span>
          <List>
            <Droppable droppableId="selecionados" key="2">
              {(provided, snapshot) => (
                <div
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  style={{
                    background: snapshot.isDraggingOver ? '#dfe1e6' : '',
                    height: '100%',
                  }}
                >
                  {optionSelected.map((item, index) => (
                    <Draggable
                      draggableId={`${item.cod_campo}`}
                      key={item.cod_campo}
                      index={index}
                    >
                      {(provided, snapshot) => (
                        <div
                          id={`${item.cod_campo}`}
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <ListaItem
                            onPointerDownCapture={() => {
                              handleCampo(item.cod_campo);
                            }}
                            className={
                              selected === item.cod_campo
                                ? 'selected'
                                : 'unselected'
                            }
                          >
                            {item.des_campo}
                          </ListaItem>
                        </div>
                      )}
                    </Draggable>
                  ))}
                </div>
              )}
            </Droppable>
          </List>
          <Controller>
            <Button
              type="button"
              onClick={() => moveItemSelectedToMaxUpOrMaxDown('up')}
            >
              <MdArrowUpward />
            </Button>
            <Button
              type="button"
              onClick={() => moveItemSelectedToUpOrDown('up')}
            >
              <MdKeyboardArrowUp />
            </Button>
            <Button
              type="button"
              onClick={() => moveItemSelectedToUpOrDown('down')}
            >
              <MdKeyboardArrowDown />
            </Button>
            <Button
              type="button"
              onClick={() => moveItemSelectedToMaxUpOrMaxDown('down')}
            >
              <MdArrowDownward />
            </Button>
          </Controller>
        </ContainerItem>
      </DragDropContext>
    </Container>
  );
};
