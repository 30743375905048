import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  /* display: flex; */
  /* grid-template-columns: 300px 1fr;
  grid-template-rows: 60px 1fr;
  grid-template-areas: 'navbar navbar' 'sidebar content'; */

  /* @media (max-width: 962px) {
    grid-template-columns: 120px 1fr;
  } */
`;

export const Content = styled.div.attrs({
  className: 'container',
})`
  /* grid-area: content; */
  /* overflow: auto; */
  background-color: transparent;
`;
